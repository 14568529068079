/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start fill-height>
    <v-flex xs12>
      <v-form ref="form" lazy-validation>
        <v-layout wrap justify-start px-2 px-sm-4 px-lg-8>
          <v-flex xs12 pr-4 class="mycontainer">
            <v-layout wrap justify-start py-8>
              <v-flex xs12 lg4>
                <v-layout wrap justify-start>
                  <v-flex xs10 xl11>
                    <span class="title-2">
                      {{ $t("form.enter_details") }}
                    </span>
                    <p class="subtitle-2">
                      {{ $t("messages.warning.all_mandatory") }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 lg6 offset-2>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6 pa-lg-2 pt-4 align-self-center>
                    <p class="input-label">{{ $t("form.name") }}</p>
                    <v-text-field
                      dense
                      outlined
                      v-model="name"
                      :rules="[RULES.REQUIRED]"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pa-lg-2 pt-4 align-self-center>
                    <p class="input-label">{{ $t("form.phone") }}</p>
                    <v-text-field
                      dense
                      outlined
                      v-model="phone"
                      :rules="[RULES.REQUIRED]"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pa-lg-2 pt-4 align-self-center>
                    <p class="input-label">{{ $t("form.address") }}</p>
                    <v-textarea rows="4" dense outlined v-model="address" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
    <v-flex xs12>
      <v-card tile height="100%">
        <v-layout wrap justify-start fill-height>
          <v-flex xs12 px-8 text-right align-self-center>
            <span class="px-4">
              <AppNavButton
                :route="{ name: 'departments' }"
                color="primary"
                custom-class="pa-5"
                outlined
              >
                <template #content>
                  <span style="color: #3278ff">
                    {{ $t("buttons.cancel") }}
                  </span>
                </template>
              </AppNavButton>
            </span>

            <span class="px-4">
              <AppButton @submit="manageForm" custom-class="pa-5">
                <template #content>
                  {{ $t(`pages.departments.${getButtonLabel}`) }}
                </template>
              </AppButton>
            </span>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <SuccessConfirmation
      v-model="showSuccess"
      :title="successTitle"
      :type="successType"
      @submit="$router.go(-1)"
      @cancel="toggleSuccess"
    />
  </v-layout>
</template>

<script>
import { RULES } from "@/libs/helper/components/rules";

export default {
  name: "AddDepartment",
  data: () => ({
    RULES,
    showSuccess: false,
    name: null,
    phone: null,
    address: null,
  }),
  created() {
    if (this.$route.params.id) this.getData();
  },
  computed: {
    getButtonLabel() {
      return this.$route.params.id ? "update_department" : "add_department";
    },
    successTitle() {
      return this.$route.params.id
        ? "pages.departments.department_updated"
        : "pages.departments.department_added";
    },
    successType() {
      return this.$route.params.id ? "edit" : "department";
    },
  },
  mounted() {
    const breadCrumbs = [
      {
        name: "departments",
        text: "pages.departments.title",
      },
      {
        name: this.$route.name,
        text: this.$route.params.id
          ? "pages.departments.update_department"
          : "pages.departments.add_department",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
  methods: {
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    getData() {
      this.$store
        .dispatch("hospital/getDepartment", this.$route.params.id)
        .then((res) => {
          this.name = res.name;
          this.phone = res.phone;
          this.address = res.address;
        });
    },
    manageForm() {
      if (this.$route.params.id) this.editDepartment();
      else this.addDepartment();
    },
    addDepartment() {
      if (!this.$refs.form.validate()) return;
      const data = {
        name: this.name,
        phone: this.phone,
        address: this.address,
      };
      this.$store
        .dispatch("hospital/addDepartment", data)
        .then(() => {
          this.$refs.form.reset();
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
    editDepartment() {
      if (!this.$refs.form.validate()) return;
      const data = {
        name: this.name,
        phone: this.phone,
        address: this.address,
        id: this.$route.params.id,
      };
      this.$store
        .dispatch("hospital/editDepartment", data)
        .then(() => {
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
  },
};
</script>
<style>
.mycontainer {
  max-height: 80vh;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
}
</style>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
